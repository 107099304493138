import styled from 'styled-components';

export const BannerWrapper = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgb(2, 6, 12);
  background: linear-gradient(
    45deg,
    rgba(2, 6, 12, 1) 25%,
    rgba(13, 61, 71, 1) 100%
  );
  position: relative;
  overflow: hidden;

  .banner__button {
    display: inline-flex;
    margin-bottom: 40px;
  }

  .container {
    z-index: 2;
  }

  & .particles-canvas {
    position: absolute !important;
    left: unset !important;
    right: 0;
    top: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 120px 0;
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const BannerTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.gray025};
  font-size: 5.6rem;
  line-height: 6.72rem;
  font-weight: 500;
  max-width: 843px;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 4.2rem;
    line-height: 5.04rem;
  }
`;

export const BannerSubTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.text};
  color: ${({ theme }) => theme.colors.gray050};
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 400;
  max-width: 519px;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
`;

export const BannerDivider = styled.span`
  display: block;
  width: 40px;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.coral500};
  margin-bottom: 40px;
`;

export const BannerDots = styled.ul`
  text-align: left;
  bottom: -150px;

  li {
    margin: 0;

    &:not(:first-child) {
      margin-left: 20px;
    }

    &.slick-active button {
      background-color: ${({ theme }) => theme.colors.coralInteractive};

      &::before {
        content: '';
      }
    }

    button {
      background-color: ${({ theme }) => theme.colors.gray300};
      border-radius: 2px;

      &::before {
        content: '';
      }
    }
  }
`;
