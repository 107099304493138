import styled from 'styled-components';

export const Section = styled.section`
  padding: 80px 0;
`;

export const Container = styled.div`
  max-width: 519px;
  margin: 0 auto;

  > p {
    margin-bottom: 16px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 20px;
  margin-bottom: 3px;

  .form-group {
    width: 100%;
  }
`;

export const InputLegend = styled.p`
  font-size: 1.2rem;
`;

export const ModalToggler = styled.button`
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.2rem;
  border: none;
  display: inline-block;
  color: inherit;
  background: none;
  text-decoration: underline;
  margin-left: 0.5rem;

  &:hover {
    text-decoration: none;
  }
`;
