import axios from 'axios';

/**
 * RD Station API Fetch
 *
 * @author Lucas Santana | Leme Inteligência Forense
 */

export const RD_API_KEY = process.env.NEXT_PUBLIC_RDSTATION_KEY;

export const sendConversionNotification = (payload) => {
  const rdOptions = {
    method: 'POST',
    url: 'https://api.rd.services/platform/conversions',
    params: { api_key: RD_API_KEY },
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: {
      event_type: 'CONVERSION',
      event_family: 'CDP',
      payload: payload,
    },
  };

  axios
    .request(rdOptions)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
