/**
 * Hero Banner texts on home
 */

export const bannersList: { id: number; title: string; subtitle: string }[] = [
  {
    id: 1,
    title: 'Tecnologia e inovação em investigação de ativos',
    subtitle: 'A revolução na pesquisa de bens com inteligência de dados.',
  },
  {
    id: 2,
    title: '+R$200 bilhões em patrimônio identificados',
    subtitle:
      'Ferramentas e metodologias para localização de bens com resultados comprovados.',
  },
  {
    id: 3,
    title: 'Soluções para recuperação de crédito, fácil e assertiva',
    subtitle:
      'Cruzamento de dados, localização de dados de devedores, informações cadastrais, busca de ativos.',
  },
];
