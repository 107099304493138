import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const ToastButton = styled.button`
  font-size: 3.2rem;
  line-height: 0;
  background-color: transparent;
  border: none;
`;
interface ILemeToast {
  show: boolean;
  children: React.ReactNode;
  type?: string;
  handleClose?: () => void;
}
export const ToastContainer = styled.div<ILemeToast>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
  color: ${({ type }) =>
    type === 'error'
      ? ({ theme }) => theme.colors.danger600
      : ({ theme }) => theme.colors.success600};
  max-width: 1300px;
  width: 95%;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 2px;
  padding: 21px 24px;
  background-color: ${({ type }) =>
    type === 'error'
      ? ({ theme }) => theme.colors.danger100
      : ({ theme }) => theme.colors.success100};
  box-shadow: 0px 3px 10px #1f2b3726;
  animation: ${fadeIn} 0.3s ease;

  ${ToastButton} {
    color: ${({ type }) =>
      type === 'error'
        ? ({ theme }) => theme.colors.danger600
        : ({ theme }) => theme.colors.success600};
  }
`;

/**
 * Toast component
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @return JSX.Element
 */

export function LemeToast({
  show,
  children,
  type,
  handleClose,
}: ILemeToast): JSX.Element {
  return (
    <ToastContainer show={show} onClick={handleClose} type={type}>
      {children}
    </ToastContainer>
  );
}
